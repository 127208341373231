import React from "react";
import LineGraph from "../LineGraph/LineGraph";

const Graph = () => {
  return (
    <div>
      <LineGraph />
    </div>
  );
};

export default Graph;

import React from "react";
import CustomerTable from "../components/CustomerTable/CustomerTable";

const Tickets = () => {
  return (
    <>
      <CustomerTable />
    </>
  );
};

export default Tickets;

import React from "react";
import Routers from "../../routers/Routers";
import "./maindash.css";

const MainDash = () => {
  return (
    <div className="maindash">
      <Routers />
    </div>
  );
};

export default MainDash;
